import Vue from 'vue'
import Router from 'vue-router'
import Login from './views/login.vue'
import Forgot from './views/forgot.vue'
import index from './components/index.vue'
import Verify from './views/verify.vue'
import PasswordUpdate from './views/changePassword.vue';


import Dashboard from "./pages/dashboard";
import Customers from "./pages/customers-v2"
import Admin from "./pages/admin"
import AdminIndex from "./pages/admin-index"
import AdminTicketTypes from "./pages/admin-ticket-type"
import AdminBank from "./pages/admin-banks"
import InvoiceType from "./pages/admin-invoice-type"
import Users from "./pages/admin-users";
import CreateUser from "./pages/admin-users-add";
import ViewProfile from "./pages/view-profile";
import ValueChain from "./pages/value-chain";
import TicketPurchased from "./pages/ticket-purchased";
import Events from "./pages/events"
import Invoice from "./pages/invoices"
import EventsDashboard from "./pages/event-dashboard"
import MpesaTransaction from "./pages/mpesa-transaction"
import adminCategories from './pages/admin-categories.vue'


Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        //{ path: '', name: 'home', component: Home },
        { path: '/', name: 'login', component: Login },
        { path: '/verify', name: 'verify', component: Verify },
        { path: '/forgot', name: 'forgot', component: Forgot },
        { path: '/changepwd', name: 'changePWD', component: PasswordUpdate },
        { path: '/app', component: index, children: [
            { path: '', name: 'dashboard', component: Dashboard, meta: { name: 'Dashboard' } },
            { path: 'customers', name: 'customers', component: Customers, meta: { name: 'Customers' } },
            { path: 'profiles/view', name: 'profiles-view', component: ViewProfile, meta: { name: 'View Profiles' } },
            { path: 'values/chain', name: 'value-chain-view', component: ValueChain, meta: { name: 'View Value Chain' } },
            { path: 'ticket/purchased', name: 'ticketPurchased', component: TicketPurchased, meta: { name: 'View Ticket Purchased' } },
            { path: 'events', name: 'view-events', component: Events, meta: { name: 'View Events' } },
            { path: 'invoice', name: 'view-invoice', component: Invoice, meta: { name: 'View Invoice' } },
            { path: 'mpesa', name: 'view-mpesa', component: MpesaTransaction, meta: { name: 'View Mpesa' } },
            { path: 'events/:id', name: 'view-events-dashboard', component: EventsDashboard, meta: { name: 'View Event Stats' } },
            { path: 'admin', component: AdminIndex, children: [
                { path: '', name: 'admin', component: Users, meta: { name: 'Admin' } },
                { path: 'ticket/types', name: 'ticketTypes', component: AdminTicketTypes, meta: { name: 'Admin > Ticket Types' } },
                { path: 'banks', name: 'banks', component: AdminBank, meta: { name: 'Admin > Banks' } },
                { path: 'categories', name: 'category', component: adminCategories, meta: { name: 'Admin > Category' } },
                { path: 'invoice/type', name: 'InvoiceType', component: InvoiceType, meta: { name: 'Admin > Invoice Type' } },
                { path: 'users', name: 'users', component: Users, meta: { name: 'Admin > Users' } },
                { path: 'users/add', name: 'addUser', component: CreateUser, meta: { name: 'Admin > Create User' } },
            ] },
        ] },
    ]
})
