<template>
    <div class="l-main">
        <div class="l-main-content">
            <div class="l-form row bg-white-shadow  border-top" v-if="eventData.hasMultipleShow == 1">
                <div class="col-8">
                    <h3>Summary Event : {{ eventData.eventName }}</h3>
                </div>
                <div class="col-4 text-right">
    
                </div>
                <div class="col-6">
                    <label>Select Shows</label>
                    <select v-model="event_show_id" @change="getEventShowsVenue(event_show_id)">
                                                                                    <option value>Select Event Shows</option>
                                                                                    <option v-for="eventShow in eventShows" :key="eventShow.event_show_id" :value="eventShow.event_show_id">{{ eventShow.show }}</option>
                                                                                </select>
                </div>
                <div class="col-6">
                    <label>Select Venue</label>
                    <select v-model="event_show_venue_id">
                                                                                    <option value>Select Event Shows</option>
                                                                                    <option v-for="eventShowVe in eventShowsVenue" :key="eventShowVe.event_show_venue_id" :value="eventShowVe.event_show_venue_id">{{ eventShowVe.venue }}</option>
                                                                                </select>
                </div>
                <div class="col-4">
                    <button class="button primary" id="button-export" @click="openCreateShows()"><i class="fa fa-plus" aria-hidden="true"></i> Add Shows</button>
                </div>
                <div class="col-4"></div>
                <div class="col-4">
                    <button class="button secondary" id="button-export" @click="viewEvent()"><i class="fa fa-eye" aria-hidden="true"></i> View Event</button>
                </div>
            </div>
            <div class="l-form row bg-white-shadow  border-top" v-if="eventData.hasMultipleShow != 1" style="padding: 1em 3em;">
                <div class="col-2">
                    <img :src="imageURL" height="150px" />
                </div>
    
                <div class="col-8">
                    <h3>Summary Event : {{ eventData.eventName }} <br /><small>Start Date: {{ startDate | formatHumanReaderableDate}} <br />{{  eventData.aboutEvent | removeHtmlTags}}</small></h3>
                </div>
                <div class="col-2">
                    <button class="button secondary" id="button-export" @click="viewEvent()"><i class="fa fa-eye" aria-hidden="true"></i> View Event</button>
                </div>
    
            </div>
    
            <tabs :options="{ useUrlFragment: false }" @clicked="tabClicked" @changed="tabChanged">
                <tab name="Summary Stats">
                    <loader v-show='loadingSummary' />
                    <div class="row bg-white-shadow border-bottom border-top">
                        <div class="rsum">
                            <div class="col-4 sum yellow">
                                <div class="h">Total Purchased Tickets</div>
                                <div class="t">KES {{amount | formatNumber}}</div>
                                <div class="s hidden"><i class="fa fa-caret-up"></i> %</div>
                            </div>
                            <div class="col-4 sum red">
                                <div class="h">Total Refund Tickets</div>
                                <div class="t">KES {{refundAmount | formatNumber}}</div>
                                <div class="s hidden"><i class="fa fa-caret-up"></i> %</div>
                            </div>
                            <div class="col-4 sum orange">
                                <div class="h">Total Ticket Purchase</div>
                                <div class="t">{{ticketPurchase | formatNumber}}</div>
                            </div>
                            <div class="col-4 sum teal">
                                <div class="h">Total Ticket Redeemed</div>
                                <div class="t">{{ticketRedmeed | formatNumber}}</div>
                            </div>
                            <div class="col-4 sum red">
                                <div class="h">Total Discount</div>
                                <div class="t">KES {{discountAmount | formatNumber}}</div>
                                <div class="s hidden"><i class="fa fa-caret-up"></i> %</div>
                            </div>
                        </div>
                    </div>
                    <div class="row bg-white-shadow border-bottom border-top">
                        <div class="col">
                            <div class="col-4">
                                <h3>Ticket Purchased</h3>
                                <small>Daily trend</small>
                            </div>
                            <div class="col-8 text-right">
                                <div class="buttons small">
                                    <button class="button" @click="searchRecords(0)" :class="{'selected': days == 0}">Today</button>
                                    <button class="button" @click="searchRecords(7)" :class="{'selected': days == 7}">7d</button>
                                    <button class="button" @click="searchRecords(30)" :class="{'selected': days == 30}">30d</button>
    
                                </div>
                            </div>
                            <div class="p-top"><canvas id="purchase-chart" height="300"></canvas></div>
                        </div>
                    </div>
                    <div class="bg-white-shadow border-bottom border-top">
                        <div class="row">
                            <div class="col-6">
                                <h3>Tickets Types</h3>
                                <small class="text-grey-light">Ticket Types Purchased</small>
                            </div>
                            <div class="col-6 text-right">
                                <button class="button primary" id="button-export" @click="addTicketType">Add Ticket Type </button>
                            </div>
                        </div>
                        <div class="row" v-for="(types, index) in ticketTypes" :key="index+1">
                            <div class="col-8">
                                <div class="rbars">
                                    <div class="bar">
                                        <div class="bg" :style="{width: (((types.ticket_purchased - types.total_refund) / parseInt(types.group_ticket_quantity)) / types.total_tickets * 100) + '%' }"></div>
                                        <div class="txt">{{types.ticket_type}} <br /><small>KES {{types.amount | formatNumber}}</small></div>
                                        <div class="per">{{(((types.ticket_purchased - types.total_refund) / parseInt(types.group_ticket_quantity)) / types.total_tickets * 100) | formatPercent}}% </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div><small>Status <strong style="float: right;">{{types.status | formatStatus}}</strong></small></div>
                                <div><small>Ticket Purchased: <strong style="float: right;">{{(types.ticket_purchased / types.group_ticket_quantity) | formatNumber}} / {{types.total_tickets}}</strong></small></div>
                                <div><small>Ticket Refunded: <strong style="float: right;">{{(types.total_refund / types.group_ticket_quantity) | formatNumber}}</strong></small></div>
                                <div><small>Issued Complimentaries: <strong style="float: right;">{{types.issued_complimentary | formatNumber}}</strong></small></div>
                   
                                <div><small>Total Purchased: <strong style="float: right;">KES {{(types.amount * (parseInt(types.ticket_purchased) / parseInt(types.group_ticket_quantity))) | formatNumber}}</strong></small></div>
                                <div><small>Total Discount: <strong style="float: right;">KES {{types.totalDiscount  | formatNumber}}</strong></small></div>
                                <div><small>Total Refunded: <strong style="float: right;">KES {{(types.amount * (types.total_refund / types.group_ticket_quantity)) | formatNumber}}</strong></small></div>
                                <div style="border-top-style: dotted;"><br /></div>
                                <div>Overall Total:<strong style="float: right;"> KES {{((parseInt(types.amount) * (parseInt(types.ticket_purchased) / parseInt(types.group_ticket_quantity)))- (parseInt(types.totalDiscount) + parseInt(types.amount * (parseInt(types.total_refund) / parseInt(types.group_ticket_quantity))))) | formatNumber}}</strong></div>

                                <br />
                                <small style="float: right;"><button class="button primary" id="button-export" @click="openEditTickets(types)" style="margin-left: 5px;"><i class="fa fa-edit" aria-hidden="true"></i></button></small>
                                <small style="float: right;"><button class="button seondary" id="button-export" @click="openComplimentary(types)"><i class="fas fa-share-square" aria-hidden="true"></i></button></small>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab name="C2B">
                    <loader v-show='loadingC2B' />
                    <div class="row">
                        <div class="col-10">
                            <div class="input inline">
                                <input v-on:keyup.enter="searchRecordC2B" type="text" placeholder="Search Mobile ..." v-model="search_match">
                            </div>
                        </div>
                        <div class="col-2">
                            <button class="button primary" id="button-export-c2b" @click="generateExportC2B"><i class="fa fa-download" aria-hidden="true"></i> Export C2B Payments </button>
                        </div>
                    </div>
                    <div class="overflow">
                        <vuetable ref="vuetable" :api-url="url" :fields="columnsC2B" :css="css.table" :sort-order="sortOrder" track-by="paybill" :append-params="moreParams" :per-page="10" @vuetable:pagination-data="onPaginationData" @vuetable:loading="onLoading" @vuetable:loaded="onLoaded">
    
                            <div slot="name" slot-scope="props">
                                <div><strong>{{props.rowData.first_name }} {{props.rowData.last_name }} {{props.rowData.surname }}</strong></div>
                            </div>
    
                        </vuetable>
                    </div>
                    <div class="p-top"></div>
                    <div class="vuetable-pagination row">
                        <div class="col text-center">
                            <vuetable-pagination-info ref="paginationInfo"></vuetable-pagination-info>
                        </div>
                        <div class="col">
                            <vuetable-pagination ref="pagination" @vuetable-pagination:change-page="onChangePage">
                            </vuetable-pagination>
                        </div>
                    </div>
                </tab>
                <tab name="CARD">
                    <loader v-show='loadingCARD' />
                    <div class="row">
                        <div class="col-10">
                            <div class="input inline">
                                <input v-on:keyup.enter="searchRecordCARD" type="text" placeholder="Search Mobile ..." v-model="search_match_card">
                            </div>
                        </div>
                        <div class="col-2">
                            <button class="button primary" id="button-export-card" @click="generateExportCARD"><i class="fa fa-download" aria-hidden="true"></i> Export Card Payments </button>
                        </div>
                    </div>
                    <div class="overflow">
                        <vuetable ref="vuetableCARD" :api-url="url" :fields="columnsCARD" :css="css.table" :sort-order="sortOrder" track-by="paybill" :append-params="moreParamsCARD" :per-page="10" @vuetable:pagination-data="onPaginationDataCARD" @vuetable:loading="onLoadingCARD"
                            @vuetable:loaded="onLoadedCARD">
    
                            <div slot="name" slot-scope="props">
                                <div><strong>{{props.rowData.first_name }} {{props.rowData.last_name }} {{props.rowData.surname }}</strong></div>
                            </div>
                            <div slot="amount" slot-scope="props">
                                {{ props.rowData.amount * props.rowData.totalTickets }}
                            </div>
    
                        </vuetable>
                    </div>
                    <div class="p-top"></div>
                    <div class="vuetable-pagination row">
                        <div class="col text-center">
                            <vuetable-pagination-info ref="paginationInfoCARD"></vuetable-pagination-info>
                        </div>
                        <div class="col">
                            <vuetable-pagination ref="paginationCARD" @vuetable-pagination:change-page="onChangePageCARD">
                            </vuetable-pagination>
                        </div>
                    </div>
                </tab>
                <tab name="B2B">
                    <loader v-show='loadingB2B' />
                    <div class="row">
                        <div class="col-10">
                            <div class="input inline">
                                <input v-on:keyup.enter="searchRecordB2B" type="text" placeholder="Search Mobile ..." v-model="search_match_b2b">
                            </div>
                        </div>
                        <div class="col-2">
                            <button class="button primary" id="button-export-b2b" @click="generateExportB2B"><i class="fa fa-download" aria-hidden="true"></i> Export B2B Payments </button>
                        </div>
                    </div>
                    <div class="overflow">
                        <vuetable ref="vuetableB2B" :api-url="url" :fields="columnsB2B" :css="css.table" :sort-order="sortOrder" track-by="paybill" :append-params="moreParamsB2B" :per-page="10" @vuetable:pagination-data="onPaginationDataB2B" @vuetable:loading="onLoadingB2B"
                            @vuetable:loaded="onLoadedB2B">
    
                            <div slot="name" slot-scope="props">
                                <div><strong>{{props.rowData.first_name }} {{props.rowData.last_name }} {{props.rowData.surname }}</strong></div>
                            </div>
    
                        </vuetable>
                    </div>
                    <div class="p-top"></div>
                    <div class="vuetable-pagination row">
                        <div class="col text-center">
                            <vuetable-pagination-info ref="paginationInfoB2B"></vuetable-pagination-info>
                        </div>
                        <div class="col">
                            <vuetable-pagination ref="paginationB2B" @vuetable-pagination:change-page="onChangePageB2B">
                            </vuetable-pagination>
                        </div>
                    </div>
                </tab>
                <tab name="Complimentary">
                    <loader v-show='loadingComp' />
                    <div class="row">
                        <div class="col-10">
                            <div class="input inline">
                                <input v-on:keyup.enter="searchRecordComp" type="text" placeholder="Search Mobile ..." v-model="search_match_Comp">
                            </div>
                        </div>
                        <div class="col-2">
                            <button class="button primary" id="button-export-comp" @click="generateExportComp"><i class="fa fa-download" aria-hidden="true"></i> Export Complimentary </button>
                        </div>
    
                    </div>
                    <div class="overflow">
                        <vuetable ref="vuetableComp" :api-url="urlComp" :fields="columnsComp" :css="css.table" :sort-order="sortOrder" track-by="paybill" :append-params="moreParamsComp" :per-page="10" @vuetable:pagination-data="onPaginationDataComp" @vuetable:loading="onLoadingComp"
                            @vuetable:loaded="onLoadedComp">
    
                            <div slot="name" slot-scope="props">
                                <div><strong>{{props.rowData.first_name }} {{props.rowData.last_name }} {{props.rowData.surname }}</strong></div>
                            </div>
                           
    
                        </vuetable>
                    </div>
                    <div class="p-top"></div>
                    <div class="vuetable-pagination row">
                        <div class="col text-center">
                            <vuetable-pagination-info ref="paginationInfoComp"></vuetable-pagination-info>
                        </div>
                        <div class="col">
                            <vuetable-pagination ref="paginationComp" @vuetable-pagination:change-page="onChangePageComp">
                            </vuetable-pagination>
                        </div>
                    </div>
                </tab>
                <tab name="Refunded">
                    <loader v-show='loadingRefund' />
                    <div class="row">
                        <div class="col-10">
                            <div class="input inline">
                                <input v-on:keyup.enter="searchRecordRefund" type="text" placeholder="Search Mobile ..." v-model="search_match_Refund">
                            </div>
                        </div>
                        <div class="col-2">
                            <button class="button primary" id="button-export-Refund" @click="generateExportRefund"><i class="fa fa-download" aria-hidden="true"></i> Export Refund </button>
                        </div>
    
                    </div>
                    <div class="overflow">
                        <vuetable ref="vuetableRefund" :api-url="urlRefund" :fields="columnsRefund" :css="css.table" :sort-order="sortOrder" 
                        track-by="paybill" :append-params="moreParamsRefund"
                         :per-page="10" @vuetable:pagination-data="onPaginationDataRefund"
                          @vuetable:loading="onLoadingRefund"
                            @vuetable:loaded="onLoadedRefund">
    
                            <div slot="name" slot-scope="props">
                                <div><strong>{{props.rowData.first_name }} {{props.rowData.last_name }} {{props.rowData.surname }}</strong></div>
                            </div>
                            
    
                        </vuetable>
                    </div>
                    <div class="p-top"></div>
                    <div class="vuetable-pagination row">
                        <div class="col text-center">
                            <vuetable-pagination-info ref="paginationInfoRefund"></vuetable-pagination-info>
                        </div>
                        <div class="col">
                            <vuetable-pagination ref="paginationRefund" @vuetable-pagination:change-page="onChangePageRefund">
                            </vuetable-pagination>
                        </div>
                    </div>
                </tab>
            </tabs>
            <modal name="example" :width="600" :height="320" :adaptive="true">
                <div class="row">
                    <div class="col">
    
                        <div class="l-form row">
                            <div class="col">
                                <div class="col-6">
                                    <h4>Ticket Type</h4>
                                    <select v-model="ticketTypeSelect">
                                                                                                    <option value>Select Ticket Type</option>
                                                                                                    <option v-for="s_id in ticketTypesList" :key="s_id.typeId" :value="s_id.typeId">{{s_id.ticket_type}}</option>
                                                                                                </select>
                                </div>
                                <div class="col-6">
                                    <h4>Total Tickets</h4>
                                    <input type="text" v-model="totalTickets" placeholder="e.g VIP">
                                </div>
    
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h4>Group Ticket</h4>
                                    <input type="number" v-model="groupTickets">
                                </div>
                                <div class="col-6">
                                    <h4>Complimentary</h4>
                                    <input type="number" v-model="total_complimentary">
                                </div>
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h4>Is Public</h4>
                                    <select v-model="isPulicTicket">
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                </div>
                                <div class="col-6">
                                    <h4>Amount</h4>
                                <input type="number" v-model="amount">
                                </div>
                            </div>
                            <div class="col">
                                <button class="button primary" id="createType" @click="createEventTicketTypes()">Create</button>
    
                            </div>
                        </div>
                    </div>
                </div>
            </modal>
            <vue-modal-2 width="600" name="modal_create_shows" @on-close="closeShows" :headerOptions="{
                                                                        title: 'Create Shows: ',
                                                                    }" :footerOptions="{
                                                                        btn1: 'Cancel',
                                                                        btn2: 'Update',
                                                                        btn1Style: {
                                                                        backgroundColor: 'red',
                                                                        color:'white'
                                                                        },
                                                                        btn2OnClick: () => {
                                                                            createShows();
                                                                        },
                                                                        btn1OnClick: () => {
                                                                        $vm2.close('modal_create_shows');
                                                                        },
                                                                    }">
                <div class="l-form row">
                    <div class="col">
                        <div class="col-6">
                            <h4>Show Name</h4>
                            <input type="text" v-model="showName" placeholder="e.g VIP">
                        </div>
                        <div class="col-6">
                            <h4>Venue</h4>
                            <vue-tags-input v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" placeholder="Type Venue and press enter" />
                        </div>
    
                    </div>
                    <div class="col">
    
                        <h3>Show Date</h3>
                        <date-picker v-model="eventRange" type="datetime" range class="input inline"></date-picker>
    
    
                    </div>
    
                </div>
    
    
            </vue-modal-2>
            <vue-modal-2 width="600" name="modal_edit" @on-close="close" :headerOptions="{
                                                                        title: 'Edit Tickets: '+selectedTickets.ticket_type,
                                                                    }" :footerOptions="{
                                                                        btn1: 'Cancel',
                                                                        btn2: 'Update',
                                                                        btn1Style: {
                                                                        backgroundColor: 'red',
                                                                        color:'white'
                                                                        },
                                                                        btn2OnClick: () => {
                                                                            updateTickets();
                                                                        },
                                                                        btn1OnClick: () => {
                                                                        $vm2.close('modal_edit');
                                                                        },
                                                                    }">
                <div class="row">
                    <div class="col">
    
                        <div class="l-form row">
                            <div class="col">
                                <div class="col-6">
                                    <h4>Status</h4>
                                    <select v-model="selectedTickets.status">
                                                                                <option value="1">Active</option>
                                                                                <option value="2">Pending</option>
                                                                                <option value="3">Close</option>
                                                                            </select>
                                </div>
    
                                <div class="col-6">
                                    <h4>Total Tickets</h4>
                                    <input type="text" v-model="selectedTickets.total_tickets" placeholder="e.g VIP">
                                </div>
    
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h4>Group Ticket</h4>
                                    <input type="number" v-model="selectedTickets.group_ticket_quantity">
                                </div>
                                <div class="col-6">
                                    <h4>Complimentary</h4>
                                    <input type="number" v-model="selectedTickets.total_complimentary">
                                </div>
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h4>Discount</h4>
                                    <input type="number" v-model="selectedTickets.discount">
                                </div>
                                <div class="col-6">
                                    <h4>Amount</h4>
                                    <input type="number" v-model="selectedTickets.amount" disabled>
                                </div>
                            </div>
                            <div class="col">
                                <h3>--------- Ticket Colors ----------</h3>
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h5>Base Color</h5>
                                    <input type="color" id="favcolor" v-model="selectedTickets.main_color_code" name="favcolor" style="padding: 0px;">
                                </div>
                                <div class="col-6">
                                    <h5>Secondary Color</h5>
                                    <input type="color" id="favcolor" v-model="selectedTickets.color_code" name="favcolor" style="padding: 0px;">
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
    
    
            </vue-modal-2>
            <div class="row">
                <vue-modal-2 width="500" name="modal_complimentary" @on-close="closeComplimentary" :headerOptions="{
                                                title: 'Send Complimentary: '+selectedTickets.ticket_type,
                                            }" :footerOptions="{
                                                btn1: 'Cancel',
                                                btn2: 'Send Complimentary',
                                                btn1Style: {
                                                backgroundColor: 'red',
                                                color:'white'
                                                },
                                                btn2OnClick: () => {
                                                    sendComplimentary();
                                                },
                                                btn1OnClick: () => {
                                                $vm2.close('modal_complimentary');
                                                },
                                            }">
                    <div class="row">
    
                        <div class="l-form row">
                            <div class="col">
                                <div class="col-6">
                                    <h4>Phones</h4>
                                    <input type="tel" v-model="comp_msisdn" placeholder="254700000000">
                                </div>
                                <div class="col-6">
                                    <h4>Name</h4>
                                    <input type="tel" v-model="comp_name" placeholder="e.g John Doe">
                                </div>
    
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h4>Company</h4>
                                    <input type="tel" v-model="comp_company" placeholder="e.g madfun">
                                </div>
                                <div class="col-6">
                                    <h4>Quantity</h4>
                                    <input type="tel" v-model="comp_quantity">
                                </div>
                            </div>
                            <div class="col">
                                <h4>Email</h4>
                                <input type="email" v-model="comp_email" placeholder="e.g name@mail.com">
    
    
                            </div>
                            <div class="col">
                                <h4>Send SMS & Email</h4>
                                <div class="col-6">
                                    Yes:<small><input type="radio"  v-model="senderComplimentarySMS" value="1"> </small>
                                </div>
                                <div class="col-6">
                                    No:<small> <input type="radio"  v-model="senderComplimentarySMS" value="0"></small>
                                </div>
                            </div>
                        </div>
    
                    </div>
    
                </vue-modal-2>
            </div>
            <div class="row">
                <vue-modal-2 width="500" name="modal_edit_tickets" @on-close="closeTickets" :headerOptions="{
                                                title: 'Edit Tickets: '+selectTicket.first_name+' '+selectTicket.first_name,
                                            }" :footerOptions="{
                                                btn1: 'Cancel',
                                                btn2: 'Send Complimentary',
                                                btn1Style: {
                                                backgroundColor: 'red',
                                                color:'white'
                                                },
                                                btn2OnClick: () => {
                                                    modifyTicketsName();
                                                },
                                                btn1OnClick: () => {
                                                $vm2.close('modal_edit_tickets');
                                                },
                                            }">
                    <div class="row">
    
                        <div class="l-form row">
                            <div class="col">
                                <div class="col-6">
                                    <h4>Phones</h4>
                                    <input type="tel" v-model="selectTicket.msisdn" placeholder="254700000000" disabled>
                                </div>
                                <div class="col-6">
                                    <h4>Barcode</h4>
                                    <input type="tel" v-model="selectTicket.barcode" placeholder="" disabled>
                                </div>
    
                            </div>
                            <div class="col">
                                <div class="col-6">
                                    <h4>First Name</h4>
                                    <input type="tel" v-model="selectTicket.first_name" placeholder="e.g madfun">
                                </div>
                                <div class="col-6">
                                    <h4>Last Name</h4>
                                    <input type="tel" v-model="selectTicket.last_name">
                                </div>
                            </div>
    
                        </div>
    
                    </div>
    
                </vue-modal-2>
            </div>
        </div>
    </div>
</template>

<script>
import loader from "@/components/loader"
import tableCss from "@/components/css.js";
import { Tabs, Tab } from 'vue-tabs-component';
import Chart from "chart.js";
import store from "../store";
import $ from 'jquery';
import numeral from "numeral";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
import VueTagsInput from '@johmun/vue-tags-input';
import { ColourPicker } from "vue-colour-picker"

export default {
    data() {
        return {
            imageURL: "",
            startDate: "",
            senderComplimentarySMS: 1,
            refundAmount:0,
            discountAmount:0,
            loadingSummary: true,
            loadingB2B: true,
            loadingCARD: true,
            loadingC2B: true,
            loadingComp: true,
            loadingRefund: true,
            css: tableCss,
            url: "https://api.v1.interactive.madfun.com/v1/api/dashboard/generate/report",
            urlComp: "https://api.v1.interactive.madfun.com/v1/api/dashboard/complimentary/report",
            urlRefund: "https://api.v1.interactive.madfun.com/v1/api/dashboard/refunded/report",
            base_colour: '#F6D02A',
            second_colour: '#B59404',
            comp_msisdn: "",
            comp_name: "",
            comp_company: "",
            comp_email: "",
            comp_quantity: 1,
            selectedTab: 'summary',
            event_show_id: "",
            tags: [],
            selectTicket: {
                msisdn: "",
                first_name: "",
                last_name: "",
                surname: null,
                barcode: "",
                email: null,
                ticket_type: ""
            },
            showName: "",
            tag: "",
            event_show_venue_id: "",
            eventData: {
                eventName: "",
                company: "",
                venue: "",
                eventID: "",
                posterURL: "",
                hasMultipleShow: "",
                isPublic: "",
                aboutEvent: "",
                status: "",
                start_date: "",
                end_date: "",
                category_id: ""
            },
            eventRange: [],
            eventShows: [],
            eventShowsVenue: [],
            totalTickets: 0,
            totalPurchase: [],
            days: 7,
            total_complimentary: 0,
            groupTickets: 1,

            time3: "",
            startDate: "",
            endDate: "",
            ticketPurchase: 0,
            ticketRedmeed: 0,
            ticketTypes: [],
            amount: 0,
            selectedTickets: {
                ticket_type: "",
                event_ticket_id: "",
                group_ticket_quantity: "",
                status: "",
                total_complimentary: "",
                amount: "",
                total_tickets: "",
            },
            totalTickets: 10,
            ticketTypeSelect: "",
            errorcreateStatus: false,
            errorsMessage: "",
            ticketTypesList: [],
            amount: 0,
            columnsComp: [{
                    name: "msisdn",
                    title: 'Phone',
                },
                {
                    name: "email",
                    title: 'Email',
                    sortField: 'email',
                },

                {
                    name: 'name',
                    title: 'Names',
                    sortField: 'name'

                },
                {
                    name: 'alias_name',
                    title: 'Alias Names',
                    sortField: 'alias_name'

                },


                {
                    name: 'ticket_type',
                    title: 'Ticket Type',
                    sortField: 'ticket_type'

                },
                {
                    name: 'barcode',
                    title: 'Barcode',
                    sortField: 'barcode',

                },


                {
                    name: 'created',
                    title: 'Date',
                    sortField: 'created',
                },
                'action'
            ],
            columnsRefund: [{
                    name: "msisdn",
                    title: 'Phone',
                },
                {
                    name: "email",
                    title: 'Email',
                    sortField: 'email',
                },

                {
                    name: 'name',
                    title: 'Names',
                    sortField: 'name'

                },
                {
                    name: 'alias_name',
                    title: 'Alias Names',
                    sortField: 'alias_name'

                },


                {
                    name: 'ticket_type',
                    title: 'Ticket Type',
                    sortField: 'ticket_type'

                },
                {
                    name: 'barcode',
                    title: 'Barcode',
                    sortField: 'barcode',

                },
                {
                    name: 'refundPurpose',
                    title: 'Refund Purpose',
                    sortField: 'refundPurpose'

                },

                {
                    name: 'created',
                    title: 'Date',
                    sortField: 'created',
                },
                'action'
            ],
            columnsB2B: [{
                    name: "msisdn",
                    title: 'Phone',
                },
                {
                    name: "email",
                    title: 'Email',
                    sortField: 'email',
                },

                {
                    name: 'name',
                    title: 'Names',
                    sortField: 'name'

                },

                {
                    name: 'ticket_type',
                    title: 'Ticket Type',
                    sortField: 'ticket_type'

                },
                {
                    name: 'barcode',
                    title: 'Barcode',
                    sortField: 'barcode',

                },
                {
                    name: 'mpesa_code',
                    title: 'M-Pesa Receipt'
                },
                {
                    name: 'totalTickets',
                    title: 'Total Tickets'
                },
                {
                    name: 'mpesa_amount',
                    title: 'Amount'
                },
                {
                    name: 'created',
                    title: 'Date',
                    sortField: 'created',
                }
            ],
            columnsC2B: [{
                    name: "msisdn",
                    title: 'Phone',
                },
                {
                    name: "email",
                    title: 'Email',
                    sortField: 'email',
                },

                {
                    name: 'name',
                    title: 'Names',
                    sortField: 'name'

                },

                {
                    name: 'ticket_type',
                    title: 'Ticket Type',
                    sortField: 'ticket_type'

                },
                {
                    name: 'barcode',
                    title: 'Barcode',
                    sortField: 'barcode',

                },
                {
                    name: 'mpesa_code',
                    title: 'M-Pesa Receipt'
                },
                {
                    name: 'totalTickets',
                    title: 'Total Tickets'
                },
                {
                    name: 'mpesa_amount',
                    title: 'Amount'
                },
                {
                    name: 'created',
                    title: 'Date',
                    sortField: 'created',
                }

            ],
            columnsCARD: [{
                    name: "msisdn",
                    title: 'Phone',
                },
                {
                    name: "email",
                    title: 'Email',
                    sortField: 'email',
                },

                {
                    name: 'name',
                    title: 'Names',
                    sortField: 'name'

                },

                {
                    name: 'ticket_type',
                    title: 'Ticket Type',
                    sortField: 'ticket_type'

                },
                {
                    name: 'barcode',
                    title: 'Barcode',
                    sortField: 'barcode',

                },
                {
                    name: 'TransID',
                    title: 'Card Receipt'
                },
                {
                    name: 'totalTickets',
                    title: 'Total Tickets'
                },
                {
                    name: 'amount',
                    title: 'Amount'
                },
                {
                    name: 'created',
                    title: 'Date',
                    sortField: 'created',
                }
            ],
            range: '',
            search_match: '',
            search_match_card: '',
            search_match_b2b: '',
            search_match_Comp: '',
            search_match_Refund: '',
            loadOnStart: true,
            sortOrder: [{
                field: 'created',
                direction: 'desc'
            }],
            is_partial_resulting: false,
            moreParams: {
                start: '',
                filter: '',
                end: ''
            },
            moreParamsB2B: {
                start: '',
                filter: '',
                end: ''
            },
            moreParamsComp: {
                start: '',
                filter: '',
                end: ''
            },
            moreParamsRefund: {
                start: '',
                filter: '',
                end: '',
                isRefunded:1
            },
            moreParamsCARD: {
                start: '',
                filter: '',
                end: ''
            },
            isPulicTicket:1
        }
    },
    components: {
        DatePicker,
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        VueTagsInput,
        'colour-picker': ColourPicker,
        Tabs,
        Tab,
        loader
    },
    mounted() {

        let vm = this;
        if (!vm.$route.params.id) {
            this.$router.push({
                name: 'view-events'
            });
        }
        this.moreParams.eventID = vm.$route.params.id;
        this.moreParamsB2B.eventID = vm.$route.params.id;
        this.moreParamsCARD.eventID = vm.$route.params.id;
        this.moreParamsRefund.eventID = vm.$route.params.id;
        this.moreParamsB2B.mode = "B2B";
        this.moreParamsCARD.mode = "CARD";
        this.$refs.vuetable.refresh();
        this.$refs.vuetableB2B.refresh();
        this.$refs.vuetableCARD.refresh();
        this.$refs.vuetableComp.refresh();
        this.$refs.vuetableRefund.refresh();

        vm.loading = false
        if (!vm.$cookies.get("selectedEvent")) {
            this.$router.push({
                name: 'view-events'
            });
        }
        vm.eventData = vm.$cookies.get("selectedEvent");
        vm.getTicketTypesList()
        if (vm.eventData.hasMultipleShow == 1) {

            vm.getEventShows();
        } else {
            vm.getsummary();
            vm.getTicketPurchase();
            vm.getTicketTypes();

        }



    },
    methods: {
        tabClicked(selectedTab) {
            console.log("tab: " + selectedTab.tab.name)
            this.loading = true
            let vm = this
            if (selectedTab.tab.name == "B2B") {
                this.moreParamsB2B.mode = "B2B";
                this.moreParamsB2B.eventID = vm.$route.params.id;
                this.$refs.vuetableB2B.refresh();
            } else if (selectedTab.tab.name == "C2B") {
                this.moreParamsB2B.mode = "C2B";
                this.moreParams.eventID = vm.$route.params.id;
                this.$refs.vuetable.refresh();
            } else if (selectedTab.tab.name == "CARD") {
                this.moreParamsCARD.mode = "CARD";
                this.moreParamsCARD.eventID = vm.$route.params.id;
                this.$refs.vuetableCARD.refresh();
            } else if (selectedTab.tab.name == "Complimentary") {
                this.moreParamsComp.eventID = vm.$route.params.id;
                this.$refs.vuetableComp.refresh();
            } 
            else if (selectedTab.tab.name == "Refunded") {
                this.moreParamsRefund.eventID = vm.$route.params.id;
                this.$refs.vuetableRefund.refresh();
                this.moreParamsRefund.isRefunded = 1;
            }
            else {

                vm.getTicketTypesList()
                if (vm.eventData.hasMultipleShow == 1) {

                    vm.getEventShows();
                } else {
                    vm.getsummary();
                    vm.getTicketPurchase();
                    vm.getTicketTypes();

                }

            }
            this.loading = false
        },
        tabChanged(selectedTab) {
            console.log("tab: " + selectedTab.tab.name)
            let vm = this
            if (selectedTab.tab.name == "B2B") {
                this.moreParamsB2B.mode = "B2B";
                this.moreParamsB2B.eventID = vm.$route.params.id;
                this.$refs.vuetableB2B.refresh();
            } else if (selectedTab.tab.name == "C2B") {
                this.moreParamsB2B.mode = "C2B";
                this.moreParams.eventID = vm.$route.params.id;
                this.$refs.vuetable.refresh();
            } else if (selectedTab.tab.name == "CARD") {
                this.moreParamsCARD.mode = "CARD";
                this.moreParamsCARD.eventID = vm.$route.params.id;
                this.$refs.vuetableCARD.refresh();
            } else if (selectedTab.tab.name == "Complimentary") {
                this.moreParamsComp.eventID = vm.$route.params.id;
                this.$refs.vuetableComp.refresh();
            }
            else if (selectedTab.tab.name == "Refunded") {
                this.moreParamsRefund.eventID = vm.$route.params.id;
                this.$refs.vuetableRefund.refresh();
                this.moreParamsRefund.isRefunded = 1;
            }
             else {
                vm.loadingSummary = true
                vm.getTicketTypesList()
                if (vm.eventData.hasMultipleShow == 1) {
                    vm.getEventShows();
                } else {
                    vm.getsummary();
                    vm.getTicketPurchase();
                    vm.getTicketTypes();
                }
                vm.loadingSummary = false
            }
            this.loading = false

        },
        sendComplimentary() {
            let vm = this

            $.post({
                url: store.state.rootUrl + "v1/api/main/complimentary/ticket",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    event_ticket_id: vm.selectedTickets.event_ticket_id,
                    msisdn: vm.comp_msisdn,
                    name: vm.comp_name,
                    company: vm.comp_company,
                    email: vm.comp_email,
                    unique_id: Math.floor(Math.random() * (99999999999 - 1000000 + 1)) + 1000000,
                    quantity: vm.comp_quantity,
                    show: vm.eventData.hasMultipleShow,
                    sendSMSEmail: vm.senderComplimentarySMS,
                    source: "WEB"
                }),
                success: function(response, status, jQxhr) {
                    console.log(JSON.stringify(response))

                    vm.$swal('Sent', 'Successfully Sent Complimentary Tickets', 'success')
                    vm.closeComplimentary()

                },
                error: function(jQxhr, status, error) {
                    vm.$swal('Failed', 'Failed to Sent Complimentary Tickets', 'error')
                    vm.closeComplimentary()
                }
            });

        },
        openComplimentary(props) {
            this.selectedTickets = props
            this.$vm2.open('modal_complimentary')
        },
        closeComplimentary() {
            this.$vm2.close('modal_complimentary')
        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        closeShows() {
            this.$vm2.close('modal_create_shows')
        },
        viewEvent() {
            let vm = this
            window.open('https://madfun.com/event/' + vm.$route.params.id, '_blank')
        },
        createShows() {
            let vm = this
            const listArray = []

            vm.tags.forEach((element, index, array) => {
                listArray.push(element.text)
                console.log(element.text)

            });
            let venues = listArray.join(",");
            $.post({
                url: store.state.rootUrl + "v1/api/event/add/show",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    eventID: vm.$route.params.id,
                    show: vm.showName,
                    venue: venues,
                    start_date: vm.format_date_time(vm.eventRange[0]),
                    end_date: vm.format_date_time(vm.eventRange[1]),
                    source: "WEB"
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        vm.$swal('Error', 'Failed to Updated. <br />Reason: ' + response.data.statusDescription, 'error')
                    } else {
                        vm.$swal('Sent', 'Successfully Updated event ticket Type', 'success')
                        vm.closeShows()
                        vm.searchRecords(vm.days)
                    }
                },
                error: function(jQxhr, status, error) {
                    vm.$swal('Failed', 'Failed Updated event ticket Type', 'error')
                    vm.close()
                }
            });
        },
        EditTicketsName(props) {
            this.selectTicket = props
            console.log("Tickets: " + JSON.stringify(props))
            this.$vm2.open('modal_edit_tickets')

        },
        format_date(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD')
            }
        },
        format_date_time(value) {
            if (value) {
                return moment(String(value)).format('YYYY-MM-DD HH:mm:ss')
            }
        },
        closeTickets() {

            this.$vm2.close('modal_edit_tickets')
        },
        close() {
            this.$vm2.close('modal_edit')
        },
        openEditTickets(props) {
            this.selectedTickets = props
            this.$vm2.open('modal_edit')

        },
        openCreateShows() {
            this.$vm2.open('modal_create_shows')
        },
        searchRecords(v) {
            let vm = this;
            if (vm.time3 != "") {
                vm.days = 0;
                var timString = vm.time3 + "";
                var arrayTime = timString.split(",");
                vm.startDate = vm.format_date(arrayTime[0]) + " 00:00:00";
                vm.endDate = vm.format_date(arrayTime[1]) + " 23:59:59";

            } else {
                vm.days = v;
            }
            vm.getTicketPurchase();

        },
        addTicketType() {
            this.$modal.show('example')
        },
        updateTickets() {
            let vm = this
            $.post({
                url: store.state.rootUrl + "v1/api/event/type/edit",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    typeId: vm.selectedTickets.typeId,
                    event_ticket_id: vm.selectedTickets.event_ticket_id,
                    amount: vm.selectedTickets.amount,
                    color_code: vm.selectedTickets.color_code,
                    main_color_code: vm.selectedTickets.main_color_code,
                    total_tickets: vm.selectedTickets.total_tickets,
                    groupTickets: vm.selectedTickets.group_ticket_quantity,
                    total_complimentary: vm.selectedTickets.total_complimentary,
                    event_show_venue_id: vm.event_show_venue_id,
                    status: vm.selectedTickets.status,
                    discount: vm.selectedTickets.discount,
                    source: "WEB"
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code != 200) {
                        vm.$swal('Error', 'Failed to Updated. <br />Reason: ' + response.data.statusDescription, 'error')
                    } else {
                        vm.$swal('Sent', 'Successfully Updated event ticket Type', 'success')
                        vm.close()
                        vm.searchRecords(vm.days)
                    }
                },
                error: function(jQxhr, status, error) {
                    vm.$swal('Failed', 'Failed Updated event ticket Type', 'error')
                    vm.close()
                }
            });
        },
        createEventTicketTypes() {
            let vm = this;
            vm.$swal({
                title: 'Are you sure?',
                text: 'You want to create event ticket Type ',
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, create it!',
                cancelButtonText: 'Cancel',
                showCloseButton: true,
                showLoaderOnConfirm: true
            }).then((result) => {
                if (result.value) {
                    $.post({
                        url: store.state.rootUrl + "v1/api/event/type/create",
                        type: "POST",
                        data: JSON.stringify({
                            api_key: vm.$cookies.get("key"),
                            typeId: vm.ticketTypeSelect,
                            eventId: vm.$route.params.id,
                            amount: vm.amount,
                            total_tickets: vm.totalTickets,
                            groupTickets: vm.groupTickets,
                            total_complimentary: vm.total_complimentary,
                            event_show_venue_id: vm.event_show_venue_id,
                            isPublic: vm.isPulicTicket,
                            source: "WEB"
                        }),
                        success: function(response, status, jQxhr) {
                            if (response.data.code != 200) {
                                vm.$swal('Error', 'Failed to create. <br />Reason: ' + response.data.statusDescription, 'error')
                            } else {
                                vm.$swal('Sent', 'Successfully created event ticket Type', 'success')
                                vm.$modal.hide('example')
                                vm.searchRecords(vm.days)
                            }
                        },
                        error: function(jQxhr, status, error) {
                            vm.$swal('Failed', 'Failed created event ticket Type', 'error')
                            vm.$modal.hide('example')
                        }
                    });

                } else {
                    vm.$swal('Cancelled', 'Cancel to created event ticket Type', 'info')
                    vm.$modal.hide('example')
                }
            })

        },
        getEventShows() {
            let vm = this
            $.post({
                url: store.state.rootUrl + "v1/api/event/view/show",
                type: "POST",
                data: JSON.stringify({
                    api_key: "4ba0e1aae090cdefc1887d2689b25e3f",
                    eventID: vm.$route.params.id,
                    source: 'WEB'
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code == 200) {
                        vm.eventShows = response.data.data;
                        vm.event_show_id = response.data.data[0].event_show_id;
                        vm.getEventShowsVenue(response.data.data[0].event_show_id)

                    } else {
                        vm.eventShows = []
                    }
                    vm.loadingSummary = false;

                },
                error: function(jQxhr, status, error) {
                    vm.eventShows = []
                    vm.loadingSummary = false;
                }
            });
        },
        getEventShowsVenue(event_show_id) {
            let vm = this
            $.post({
                url: store.state.rootUrl + "v1/api/event/view/show/venue",
                type: "POST",
                data: JSON.stringify({
                    api_key: "4ba0e1aae090cdefc1887d2689b25e3f",
                    event_show_id: event_show_id,
                    source: 'WEB'
                }),
                success: function(response, status, jQxhr) {
                    if (response.data.code == 200) {
                        vm.event_show_venue_id = response.data.data[0].event_show_venue_id
                        vm.eventShowsVenue = response.data.data;
                        vm.getsummary();
                        vm.getTicketPurchase();
                        vm.getTicketTypes()

                    } else {
                        vm.eventShowsVenue = []
                    }

                },
                error: function(jQxhr, status, error) {
                    vm.eventShowsVenue = []
                }
            });
        },
        getsummary() {
            let vm = this;
            vm.loadingSummary = true;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/events/summary",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    eventID: vm.$route.params.id,
                    event_show_venue_id: vm.event_show_venue_id,
                    source: 'WEB'
                }),
                success: function(response, status, jQxhr) {
                    var res = response.data.data;
                    vm.totalTickets = Number(response.data.data[0].allTickets);
                    vm.ticketPurchase = Number(response.data.data[0].purchased);
                    vm.ticketRedmeed = Number(response.data.data[0].redeemed);
                    vm.amount = Number(response.data.data[0].totalAmountEvent);
                    vm.refundAmount = Number(response.data.data[0].totalRefund);
                    vm.discountAmount= Number(response.data.data[0].totalDiscount);
                    vm.loadingSummary = false;
                    vm.imageURL = response.data.data[0].posterURL
                    vm.startDate = response.data.data[0].start_date
                },
                error: function(jQxhr, status, error) {
                    vm.loadingSummary = false;

                }
            });
        },
        getTicketPurchase() {
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/tickets/graph",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    days: vm.days,
                    start: vm.startDate,
                    end: vm.endDate,
                    eventID: vm.$route.params.id,
                    event_show_venue_id: vm.event_show_venue_id
                }),
                success: function(response, status, jQxhr) {
                    let yData = [];
                    let xDate = [];
                    let result = response.data.data;

                    result.forEach(function(data) {
                        yData.push(data["COUNT"]);
                        xDate.push(data["Date"]);
                    });
                    var ctx = $('#purchase-chart');
                    var customersChart = new Chart(ctx, {
                        type: 'bar',
                        data: {
                            datasets: [{
                                data: yData,
                                backgroundColor: '#FFA500'
                            }],
                            labels: xDate,
                        },
                        options: {
                            maintainAspectRatio: false,
                            responsive: true,
                            legend: {
                                display: false
                            },
                            scales: {
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    }
                                }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            }
                        }
                    });
                },
                error: function(jQxhr, status, error) {}
            });
        },
        getTicketTypes() {
            let vm = this;
            $.post({
                url: store.state.rootUrl + "v1/api/dashboard/tickets/types",
                type: "POST",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    days: vm.days,
                    start: vm.startDate,
                    end: vm.endDate,
                    eventID: vm.$route.params.id,
                    event_show_venue_id: vm.event_show_venue_id,
                    source: 'WEB',

                }),
                success: function(response, status, jQxhr) {
                    var res = response.data.data;
                    vm.ticketTypes = res
                },
                error: function(jQxhr, status, error) {

                }
            });
        },
        getTicketTypesList() {
            let vm = this
            //https://api.v1.interactive.madfun.com/v1/api/event/ticket/type/dashboard/view

            $.post({
                url: store.state.rootUrl + "v1/api/event/ticket/type/dashboard/view?per_page=100",
                type: "GET",
                data: JSON.stringify({
                    api_key: vm.$cookies.get("key"),
                    limit: 30
                }),
                success: function(response, status, jQxhr) {
                    var res = response.data;
                    vm.ticketTypesList = res
                },
                error: function(jQxhr, status, error) {

                }
            });
        },
        numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        onPaginationData(paginationData) {
            this.$refs.paginationInfo.setPaginationData(paginationData)
            this.$refs.pagination.setPaginationData(paginationData)
        },
        onChangePage(page) {
            this.$refs.vuetable.changePage(page)
        },

        onLoading() {
            this.loadingC2B = true
            console.log('loading... show your spinner here')
        },
        onLoaded() {

            this.loadingC2B = false
            console.log('loaded! .. hide your spinner here');
        },
        onPaginationDataB2B(paginationData) {
            this.$refs.paginationInfoB2B.setPaginationData(paginationData)
            this.$refs.paginationB2B.setPaginationData(paginationData)
        },

        onChangePageB2B(page) {
            this.$refs.vuetableB2B.changePage(page)
        },

        onLoadingB2B() {
            this.loadingB2B = true
            console.log('loading... show your spinner here')
        },
        onLoadedB2B() {
            this.loadingB2B = false
            console.log('loaded! .. hide your spinner here');
        },
        onLoadingComp() {
            this.loadingComp = true
            console.log('loading... show your spinner here')
        },
        onLoadedComp() {
            this.loadingComp = false
            console.log('loaded! .. hide your spinner here');
        },
        onPaginationDataComp(paginationData) {
            this.$refs.paginationInfoComp.setPaginationData(paginationData)
            this.$refs.paginationComp.setPaginationData(paginationData)
        },
        onPaginationDataCARD(paginationData) {
            this.$refs.paginationInfoCARD.setPaginationData(paginationData)
            this.$refs.paginationCARD.setPaginationData(paginationData)
        },
        onChangePageComp(page) {
            this.$refs.vuetableComp.changePage(page)
        },
        onLoadingRefund() {
            this.loadingRefund = true
            console.log('loading... show your spinner here')
        },
        onLoadedRefund() {
            this.loadingRefund = false
            console.log('loaded! .. hide your spinner here');
        },
        onPaginationDataRefund(paginationData) {
            this.$refs.paginationInfoRefund.setPaginationData(paginationData)
            this.$refs.paginationRefund.setPaginationData(paginationData)
        },
        onChangePageRefund(page) {
            this.$refs.vuetableRefund.changePage(page)
        },

        onChangePageCARD(page) {
            this.$refs.vuetableCARD.changePage(page)
        },

        onLoadingCARD() {
            this.loadingCARD = true
            console.log('loading... show your spinner here')
        },
        onLoadedCARD() {
            this.loadingCARD = false
            console.log('loaded! .. hide your spinner here');
        },
        searchRecordC2B: function() {
            this.moreParams.filter = this.search_match;
            this.$refs.vuetable.refresh();
        },
        searchRecordComp: function() {
            this.moreParamsComp.filter = this.search_match_Comp;
            this.$refs.vuetableComp.refresh();
        },
        searchRecordCARD: function() {
            this.moreParamsCARD.filter = this.search_match_card;
            this.$refs.vuetableCARD.refresh();
        },
        searchRecordB2B: function() {
            this.moreParamsB2B.filter = this.search_match_b2b;
            this.$refs.moreParamsB2B.refresh();
        },
        searchRecordRefund: function() {
            this.moreParamsRefund.filter = this.search_match_Refund;
            this.$refs.moreParamsRefund.refresh();
        },
        generateExportC2B() {
            let vm = this;
            vm.uploadStatus = true;

            $('#button-export-c2b').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
            var exportRecipients = [];
            $.post({
                url: "https://api.v1.interactive.madfun.com/v1/api/dashboard/generate/report?export=1&eventID=" + vm.$route.params.id,
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("secret_key"),
                }),
                success: function(response, status, jQxhr) {
                    var item = {};
                    item.msisdn = "Phone";
                    item.email = "Email";
                    item.name = "Name";
                    item.ticket_type = "Ticket Type";
                    item.barcode = "Barcode";
                    item.mpesa_code = "M-Pesa Receipt";
                    item.totalTickets = "Total Tickets";
                    item.mpesa_amount = "Amount";
                    item.created = "Date";
                    exportRecipients.push(item);

                    for (var i = 0; i < response.data.length; i++) {

                        var item = {};
                        item.msisdn = response.data[i].msisdn;
                        item.email = response.data[i].email;
                        item.name = response.data[i].first_name + " " + response.data[i].last_name;
                        item.ticket_type = response.data[i].ticket_type;
                        item.barcode = response.data[i].barcode;
                        item.mpesa_code = response.data[i].mpesa_code;
                        item.totalTickets = response.data[i].totalTickets;
                        item.mpesa_amount = response.data[i].mpesa_amount;
                        item.created = response.data[i].created;
                        exportRecipients.push(item);
                    }

                    var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                    vm.uploadStatus = false;
                    $('#button-export-c2b').html('<i class="fa fa-download" aria-hidden="true"></i> Export C2B Tickets');

                    vm.JSONToCSVConvertor(exportRecipients, "Events_C2B_Report");

                },
                error: function(jQxhr, status, error) {}
            });
        },
        generateExportRefund() {
            let vm = this;
            vm.uploadStatus = true;

            $('#button-export-Refund').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
            var exportRecipients = [];
            $.post({
                url: "https://api.v1.interactive.madfun.com/v1/api/dashboard/refunded/report?export=1&eventID=" + vm.$route.params.id,
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("secret_key"),
                }),
                success: function(response, status, jQxhr) {
                    var item = {};
                    item.msisdn = "Phone";
                    item.email = "Email";
                    item.name = "Name";
                    item.ticket_type = "Ticket Type";
                    item.barcode = "Barcode";
                    item.mpesa_amount = "Amount";
                    item.refundPurpose = "Refund Purpose";
                    item.created = "Date";
                    exportRecipients.push(item);

                    for (var i = 0; i < response.data.length; i++) {

                        var item = {};
                        item.msisdn = response.data[i].msisdn;
                        item.email = response.data[i].email;
                        item.name = response.data[i].first_name + " " + response.data[i].last_name;
                        item.ticket_type = response.data[i].ticket_type;
                        item.barcode = response.data[i].barcode;
                        item.mpesa_amount = response.data[i].mpesa_amount;
                        item.refundPurpose = response.data[i].refundPurpose;
                        item.created = response.data[i].created;
                        exportRecipients.push(item);
                    }

                    var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                    vm.uploadStatus = false;
                    $('#button-export-Refund').html('<i class="fa fa-download" aria-hidden="true"></i> Export Refunded Tickets');

                    vm.JSONToCSVConvertor(exportRecipients, "Events_Refunded_Report");

                },
                error: function(jQxhr, status, error) {}
            });
        },
        generateExportCARD() {
            let vm = this;
            vm.uploadStatus = true;

            $('#button-export-card').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
            var exportRecipients = [];
            $.post({
                url: "https://api.v1.interactive.madfun.com/v1/api/dashboard/generate/report?export=1&eventID=" + vm.$route.params.id + "&mode=CARD",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("secret_key"),
                }),
                success: function(response, status, jQxhr) {
                    var item = {};
                    item.msisdn = "Phone";
                    item.email = "Email";
                    item.name = "Name";
                    item.ticket_type = "Ticket Type";
                    item.barcode = "Barcode";
                    item.TransID = "Trans Receipt";
                    item.totalTickets = "Total Tickets";
                    item.amount = "Amount";
                    item.created = "Date";
                    exportRecipients.push(item);

                    for (var i = 0; i < response.data.length; i++) {

                        var item = {};
                        item.msisdn = response.data[i].msisdn;
                        item.email = response.data[i].email;
                        item.name = response.data[i].first_name + " " + response.data[i].last_name;
                        item.ticket_type = response.data[i].ticket_type;
                        item.barcode = response.data[i].barcode;
                        item.TransID = response.data[i].TransID;
                        item.totalTickets = response.data[i].totalTickets;
                        item.amount = response.data[i].amount * response.data[i].totalTickets;
                        item.created = response.data[i].created;
                        exportRecipients.push(item);
                    }

                    var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                    vm.uploadStatus = false;
                    $('#button-export-card').html('<i class="fa fa-download" aria-hidden="true"></i> Export Card Payment');

                    vm.JSONToCSVConvertor(exportRecipients, "Events_Card_Report");

                },
                error: function(jQxhr, status, error) {}
            });
        },
        generateExportB2B() {
            let vm = this;
            vm.uploadStatus = true;

            $('#button-export-b2b').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
            var exportRecipients = [];
            $.post({
                url: "https://api.v1.interactive.madfun.com/v1/api/dashboard/generate/report?export=1&eventID=" + vm.$route.params.id + "&mode=B2B",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("secret_key"),
                }),
                success: function(response, status, jQxhr) {
                    var item = {};
                    item.msisdn = "Phone";
                    item.email = "Email";
                    item.name = "Name";
                    item.ticket_type = "Ticket Type";
                    item.barcode = "Barcode";
                    item.mpesa_code = "M-Pesa Receipt";
                    item.totalTickets = "Total Tickets";
                    item.mpesa_amount = "Amount";
                    item.created = "Date";
                    exportRecipients.push(item);

                    for (var i = 0; i < response.data.length; i++) {

                        var item = {};
                        item.msisdn = response.data[i].msisdn;
                        item.email = response.data[i].email;
                        item.name = response.data[i].first_name + " " + response.data[i].last_name;
                        item.ticket_type = response.data[i].ticket_type;
                        item.barcode = response.data[i].barcode;
                        item.mpesa_code = response.data[i].mpesa_code;
                        item.totalTickets = response.data[i].totalTickets;
                        item.mpesa_amount = response.data[i].mpesa_amount;
                        item.created = response.data[i].created;
                        exportRecipients.push(item);
                    }

                    var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                    vm.uploadStatus = false;
                    $('#button-export-b2b').html('<i class="fa fa-download" aria-hidden="true"></i> Export B2B Payment');

                    vm.JSONToCSVConvertor(exportRecipients, "Events_B2B_Report");

                },
                error: function(jQxhr, status, error) {}
            });
        },
        generateExportComp() {
            let vm = this;
            vm.uploadStatus = true;

            $('#button-export-comp').html('<i class="fas fa-circle-notch fa-spin"></i> Download Please Wait ...');
            var exportRecipients = [];
            $.post({
                url: "https://api.v1.interactive.madfun.com/v1/api/dashboard/complimentary/report?export=1&eventID=" + vm.$route.params.id + "&mode=B2B",
                type: "POST",
                data: JSON.stringify({
                    apiKey: vm.$cookies.get("secret_key"),
                }),
                success: function(response, status, jQxhr) {
                    var item = {};
                    item.msisdn = "Phone";
                    item.email = "Email";
                    item.name = "Name";
                    item.ticket_type = "Ticket Type";
                    item.barcode = "Barcode";
                    item.mpesa_amount = "Amount";
                    item.created = "Date";
                    exportRecipients.push(item);

                    for (var i = 0; i < response.data.length; i++) {

                        var item = {};
                        item.msisdn = response.data[i].msisdn;
                        item.email = response.data[i].email;
                        item.name = response.data[i].first_name + " " + response.data[i].last_name;
                        item.ticket_type = response.data[i].ticket_type;
                        item.barcode = response.data[i].barcode;
                        item.mpesa_amount = response.data[i].mpesa_amount;
                        item.created = response.data[i].created;
                        exportRecipients.push(item);
                    }

                    var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                    vm.uploadStatus = false;
                    $('#button-export-comp').html('<i class="fa fa-download" aria-hidden="true"></i> Export Complimentary');

                    vm.JSONToCSVConvertor(exportRecipients, "Events_Complimentary_Report");

                },
                error: function(jQxhr, status, error) {}
            });
        },
        JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
            //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
            var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;

            var CSV = '';
            //Set Report title in first row or line

            //CSV += ReportTitle + '\r\n\n';

            //This condition will generate the Label/Header
            if (ShowLabel) {
                var row = "";

                //This loop will extract the label from 1st index of on array
                for (var index in arrData[0]) {

                    //Now convert each value to string and comma-seprated
                    row += index + ',';
                }

                row = row.slice(0, -1);

                //append Label row with line break
                CSV += row + '\r\n\n\n';
            }

            //1st loop is to extract each row
            for (var i = 0; i < arrData.length; i++) {
                var row = "";

                //2nd loop will extract each column and convert it in string comma-seprated
                for (var index in arrData[i]) {
                    row += '"' + arrData[i][index] + '",';
                }

                row.slice(0, row.length - 1);

                //add a line break after each row
                CSV += row + '\r\n';
            }

            if (CSV == '') {
                alert("Invalid data");
                return;
            }

            //Generate a file name
            var fileName = "Madfun_";
            //this will remove the blank-spaces from the title and replace it with an underscore
            fileName += ReportTitle.replace(/ /g, "_");

            //Initialize file format you want csv or xls
            var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

            // Now the little tricky part.
            // you can use either>> window.open(uri);
            // but this will not work in some browsers
            // or you will not get the correct file extension

            //this trick will generate a temp <a /> tag
            var link = document.createElement("a");
            link.href = uri;

            //set the visibility hidden so it will not effect on your web-layout
            link.style = "visibility:hidden";
            link.download = fileName + ".csv";

            //this part will append the anchor tag and remove it after automatic click
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
    },
    filters: {
        formatAmount(value) {
            return numeral(value).format("0,0.00");
        },
        formatPercent(value) {
            return numeral(value).format("0,0.00");
        },
        formatNumber(value) {
            return numeral(value).format("0,0");
        },
        formatStatus(value) {
            if (value != null) {
                if (value == 1) {
                    return "Active"
                } else if (value == 2) {
                    return "Cancel"
                } else if (value == 3) {
                    return "Sold Out"
                } else {
                    return "Pending"
                }

            }
            return ""
        },
        formatHumanReaderableDate(value) {
            return moment(value).format("MMMM Do, YYYY, h:mm A");
        },
        removeHtmlTags(content) {
            return content.replace(/<\/?[^>]+(>|$)/g, ""); // Regex to remove HTML tags
        },

    }
}
</script>

<style>
.tabs-component {
    margin: 1em 0;
}

.tabs-component-tabs {
    border: solid 1px #ddd;
    border-radius: 6px;
    margin-bottom: 5px;
}

@media (min-width: 700px) {
    .tabs-component-tabs {
        border: 0;
        align-items: stretch;
        display: flex;
        justify-content: flex-start;
        margin-bottom: -1px;
    }
}

.tabs-component-tab {
    color: #999;
    font-size: 14px;
    font-weight: 600;
    margin-right: 0;
    list-style: none;
}

.tabs-component-tab:not(:last-child) {
    border-bottom: dotted 1px #ddd;
}

.tabs-component-tab:hover {
    color: #666;
}

.tabs-component-tab.is-active {
    color: #000;
}

.tabs-component-tab.is-disabled * {
    color: #cdcdcd;
    cursor: not-allowed !important;
}

@media (min-width: 700px) {
    .tabs-component-tab {
        background-color: #fff;
        border: solid 1px #ddd;
        border-radius: 3px 3px 0 0;
        margin-right: .5em;
        transform: translateY(2px);
        transition: transform .3s ease;
    }
    .tabs-component-tab.is-active {
        border-bottom: solid 1px #fff;
        z-index: 2;
        transform: translateY(0);
    }
}

.tabs-component-tab-a {
    align-items: center;
    color: inherit;
    display: flex;
    padding: .75em 1em;
    text-decoration: none;
}

.tabs-component-panels {
    padding: 4em 0;
}

@media (min-width: 700px) {
    .tabs-component-panels {
        border-top-left-radius: 0;
        background-color: #fff;
        border: solid 1px #ddd;
        border-radius: 0 6px 6px 6px;
        box-shadow: 0 0 10px rgba(0, 0, 0, .05);
        padding: 4em 2em;
    }
}
</style>
